// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_cloud_logic': 'enable',
    'aws_cloud_logic_custom': [{"id":"7d3jmnsmq2","name":"Meal_OptionCRUD","description":"","endpoint":"https://7d3jmnsmq2.execute-api.us-west-2.amazonaws.com/Development","region":"us-west-2","paths":["/Meal_Option","/Meal_Option/123"]},{"id":"wtzn0btr48","name":"Volunteer_LogsCRUD","description":"","endpoint":"https://wtzn0btr48.execute-api.us-west-2.amazonaws.com/Development","region":"us-west-2","paths":["/Volunteer_Logs","/Volunteer_Logs/123"]}],
    'aws_cognito_identity_pool_id': 'us-west-2:1590aa65-0f0a-4437-a09c-11646d3b9092',
    'aws_cognito_region': 'us-west-2',
    'aws_content_delivery': 'enable',
    'aws_content_delivery_bucket': 'sprouts-hosting-mobilehub-1151464687',
    'aws_content_delivery_bucket_region': 'us-west-2',
    'aws_content_delivery_cloudfront': 'enable',
    'aws_content_delivery_cloudfront_domain': 'd3s1kw1wsqasmd.cloudfront.net',
    'aws_dynamodb': 'enable',
    'aws_dynamodb_all_tables_region': 'us-west-2',
    'aws_dynamodb_table_schemas': [{"tableName":"sprouts-mobilehub-1151464687-Volunteer_Logs","attributes":[{"name":"name","type":"S"},{"name":"date","type":"S"},{"name":"cost","type":"N"},{"name":"endTime","type":"S"},{"name":"location","type":"S"},{"name":"meal","type":"S"},{"name":"startTime","type":"S"}],"indexes":[],"region":"us-west-2","hashKey":"name","rangeKey":"date"},{"tableName":"sprouts-mobilehub-1151464687-Meal_Option","attributes":[{"name":"Name","type":"S"},{"name":"Price","type":"N"}],"indexes":[],"region":"us-west-2","hashKey":"Name"}],
    'aws_mobile_analytics_app_id': 'ba11ebbd960b463db996534cab147134',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '607fef8f-bc24-44cd-8627-9aa18906d0e6',
    'aws_project_name': 'Sprouts',
    'aws_project_region': 'us-west-2',
    'aws_resource_bucket_name': 'sprouts-deployments-mobilehub-1151464687',
    'aws_resource_name_prefix': 'sprouts-mobilehub-1151464687',
    'aws_sign_in_enabled': 'enable',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-west-2_TLPqmyiUH',
    'aws_user_pools_web_client_id': '70264dehkp0gp5pjmniq8b53ct',
}

export default awsmobile;
